label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.input-list {
    display: flex;
    flex-direction: column;
}

.input-list-row-item {
    margin: 2px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: lightgray;
}

label {
    display: block;
}

form {
    display: flex;
    flex-direction: column;
}

form * {
    margin: 0.5rem 0;
}

form .alert * {
    margin: 0;
}

.spaced-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.spaced-section * {
    margin: 0.5rem;
}

.create-bill-form {
    margin: 0;
    height: auto;
    position: relative;
}